import { Box, Center, Flex, Heading, HStack, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { Link, Outlet, useMatch, useResolvedPath } from "react-router-dom";

interface MenuItem {
  icon: React.ReactNode;
  title: string;
  route: string;
}

export const HomeModule = React.memo(() => {
  const menuItems: MenuItem[] = [
    {
      icon: <AiOutlineUser color="#fff" fontSize={24} />,
      title: "Customer",
      route: "/customer/import",
    },
  ];
  return (
    <Flex overflow={"hidden"} flex={1}>
      <Box padding={4} background={"blue.900"}>
        <Box background={"gray.500"} height="50"></Box>
        <Stack minW={300} height="full" marginTop={8}>
          {menuItems.map((i) => (
            <NavLink menuItem={i} key={i.route} />
          ))}
        </Stack>
      </Box>
      <Flex flex={1} flexDirection="column">
        <Flex height={20} padding={5} alignItems="center">
          {/* <Text>Header</Text> */}
        </Flex>
        <Flex background={"gray.100"} flex={1} padding={7}>
          <Box background={"#fff"} flex={1} padding={4}>
            <Outlet />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
});

const NavLink = React.memo((props: { menuItem: MenuItem }) => {
  const resolved = useResolvedPath(props.menuItem.route);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link to={props.menuItem.route} key={props.menuItem.route}>
      <HStack spacing={4}>
        {props.menuItem.icon}
        <Text color={match ? "white" : "gray.300"} _hover={{color: "#fff"}} fontSize="lg" fontWeight={500}>
          {props.menuItem.title}
        </Text>
      </HStack>
    </Link>
  );
});
