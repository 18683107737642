import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Spacer,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useFormik } from "formik";
import React from "react";
import { auth } from "../../core/firebase.provider";
import { setToken, setUser } from "../../store/features/user/user.slice";
import { useAppDispatch } from "../../store/hook";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";

const ValidationSchema = yup.object({
  email: yup.string().required("Email is required.").email("Email is invalid."),
  password: yup.string().required("Password is required.").min(6, "Password must have at least 6 characters."),
});

const LoginPage = React.memo(() => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  const from = (location.state as any)?.from?.pathname || "/";

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, values.email, values.password);
        const token = await userCredential.user.getIdToken();
        dispatch(setUser(userCredential.user));
        dispatch(setToken(token));
        navigate(from);
      } catch (e: any) {
        toast({
          title: `Error`,
          description: e.message,
          status: "error",
          isClosable: true,
          position: "top",
        });
      }
    },
  });

  return (
    <Center alignSelf={"center"} flex={1}>
      <Box minWidth={500} borderWidth={1} padding={10} borderRadius={"lg"}>
        <Heading>Welcome back!</Heading>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={5} marginTop={6}>
            <FormControl isInvalid={!!formik.touched.email && !!formik.errors.email} isRequired id="email">
              <FormLabel htmlFor="email">Email address</FormLabel>
              <Input name="email" onChange={formik.handleChange} onBlur={formik.handleBlur} />
              {!!formik.touched.email && !!formik.errors.email && (
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={!!formik.touched.password && !!formik.errors.password} isRequired id="password">
              <FormLabel htmlFor="password">Password</FormLabel>
              <Input name="password" onChange={formik.handleChange} onBlur={formik.handleBlur} type="password" />
              {!!formik.touched.password && !!formik.errors.password && (
                <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
              )}
            </FormControl>
            <Button colorScheme={"blue"} type="submit" isLoading={formik.isSubmitting}>
              Submit
            </Button>
          </Stack>
        </form>
      </Box>
    </Center>
  );
});

export default LoginPage;
