import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage, ref } from "firebase/storage";
import { env } from "./environment";

const firebaseConfig = {
  apiKey: env.config.firebase.api_key,
  authDomain: env.config.firebase.auth_domain,
  projectId: env.config.firebase.project_id,
  storageBucket: env.config.firebase.storage_bucket,
  messagingSenderId: env.config.firebase.messaging_sender_id,
  appId: env.config.firebase.app_id,
  measurementId: env.config.firebase.measurement_id,
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
