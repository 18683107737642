import axios from "axios";
import { env } from "../environment";
import { chainInterceptor } from "./interceptors/chain.interceptor";

const instance = axios.create({
  baseURL: env.config.base_url,
  headers: { "Content-Type": "application/json" },
});

instance.interceptors.request.use(chainInterceptor);

export default instance;
