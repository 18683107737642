import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FirebaseApp } from "firebase/app";
import { User } from "firebase/auth";

interface IUserState {
  user?: User;
  token?: string;
  isAuthenticated: boolean;
}

const initialState: IUserState = {
  isAuthenticated: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.isAuthenticated = true;
      state.token = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
    },
  },
});

export const { setUser, logout, setToken } = userSlice.actions;

export default userSlice.reducer;
