import { SystemStyleObject } from "@chakra-ui/react";

const size: Record<string, SystemStyleObject> = {
  lg: {
    fontSize: "sm",
    px: 4,
    h: 12,
    borderRadius: "xl",
  },

  md: {
    fontSize: "sm",
    px: 4,
    h: 10,
    borderRadius: "xl",
  },

  sm: {
    fontSize: "sm",
    px: 3,
    h: 8,
    borderRadius: "xl",
  },

  xs: {
    fontSize: "xs",
    px: 2,
    h: 6,
    borderRadius: "xl",
  },
};

const sizes = {
  lg: {
    field: size.lg,
    addon: size.lg,
  },
  md: {
    field: size.md,
    addon: size.md,
  },
  sm: {
    field: size.sm,
    addon: size.sm,
  },
  xs: {
    field: size.xs,
    addon: size.xs,
  },
};

export default {
  baseStyle: {},
  sizes,
};
