import { Box, Button, Input, Stack, Text, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import * as XLSX from "xlsx";
import parse from "html-react-parser";
import { accountService } from "../../services/account.service";
import { AxiosError } from "axios";

const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls", "xml", "csv"].map((x) => `.${x}`).join(",");

const ImportUserPage = React.memo(() => {
  const toast = useToast();
  const [isImportingBuyers, setIsImportingBuyers] = useState(false);
  const [importBuyersError, setImportBuyersError] = useState<string>();

  const [isImportingSellers, setIsImportingSellers] = useState(false);
  const [importSellersError, setImportSellersError] = useState<string>();

  const handleBuyersFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      try {
        setIsImportingBuyers(true);
        setImportBuyersError(undefined);

        /* Parse data */
        const ab = e.target.result;
        const wb = XLSX.read(ab, { type: "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { header: 2 });
        const body = data.map((i: any) => ({
          address: i["Wallet address"].toString() as string,
          creditStatus: (i["Status"].toString() as string).toUpperCase(),
          creditLimit: parseInt(i["Credit limit"]) as number,
        }));

        const response = await accountService.importBuyers({ buyers: body });
        toast({
          title: "Buyers imported.",
          description: "Import buyers successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      } catch (e: any) {
        setImportBuyersError(e?.response?.data?.error);
      } finally {
        setIsImportingBuyers(false);
      }
    };
    reader.readAsArrayBuffer(file);

    e.currentTarget.value = "";
  };

  const handleSellersFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      try {
        setIsImportingSellers(true);
        setImportSellersError(undefined);

        /* Parse data */
        const ab = e.target.result;
        const wb = XLSX.read(ab, { type: "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { header: 2 });
        const body = data.map((i: any) => ({
          address: i["Wallet address"].toString() as string,
          sellerStatus: (i["Status"].toString() as string).toUpperCase(),
          commissionFee: parseFloat(i["Commission fee"]) as number,
        }));

        const response = await accountService.importSellers({ sellers: body });
        toast({
          title: "Buyers imported.",
          description: "Import buyers successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      } catch (e: any) {
        setImportSellersError(e?.response?.data?.error);
      } finally {
        setIsImportingSellers(false);
      }
    };
    reader.readAsArrayBuffer(file);

    e.currentTarget.value = "";
  };

  const onImportBuyers = () => {
    document.getElementById("import-buyers")?.click();
  };

  const onImportSellers = () => {
    document.getElementById("import-sellers")?.click();
  };

  return (
    <Stack spacing={5}>
      <Box position={"relative"}>
        <Button colorScheme={"blue"} variant="outline" onClick={onImportBuyers} isLoading={isImportingBuyers}>
          Import buyers
        </Button>
        <Text color={"red.500"} fontWeight={500}>
          {importBuyersError}
        </Text>

        <Input hidden id="import-buyers" type="file" accept={SheetJSFT} onChange={handleBuyersFile} />
      </Box>

      <Box position={"relative"}>
        <Button colorScheme={"blue"} variant="outline" onClick={onImportSellers} isLoading={isImportingSellers}>
          Import sellers
        </Button>
        <Text color={"red.500"} fontWeight={500}>
          {importSellersError}
        </Text>

        <Input hidden id="import-sellers" type="file" accept={SheetJSFT} onChange={handleSellersFile} />
      </Box>
    </Stack>
  );
});

export default ImportUserPage;
