import { Box, Flex } from "@chakra-ui/react";
import React, { Suspense } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ImportUserPage from "../pages/ImportUsers";
import LoginPage from "../pages/Login";
import { useAppSelector } from "../store/hook";
import { HomeModule } from "./HomeModule";

const Router = React.memo(() => {
  return (
    <Flex minHeight={"100vh"} flexDirection="column">
      <Routes>
        <Route path="" element={<AuthenticatedRoute element={<HomeModule />} />}>
          <Route index element={<Navigate replace to={"/customer/import"} />} />
          <Route path="/customer/import" element={<ImportUserPage />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    </Flex>
  );
});

const AuthenticatedRoute = (props: { element: React.ReactElement | null }) => {
  const location = useLocation();
  const user = useAppSelector((state) => state.user);

  return (
    <Suspense fallback={<Box></Box>}>
      {user.isAuthenticated ? props.element : <Navigate replace to={"/login"} state={{ from: location }} />}
    </Suspense>
  );
};

export default Router;
