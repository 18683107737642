import { SystemStyleObject } from "@chakra-ui/react";
import Input from "./input";

const sizes = {
  xs: Input.sizes.xs.field,
  sm: Input.sizes.sm.field,
  md: Input.sizes.md.field,
  lg: Input.sizes.lg.field,
};

export default {
  sizes,
};
