import { extendTheme } from "@chakra-ui/react";
import Button from "./components/button";
import Input from "./components/input";
import Textarea from "./components/textarea";
import Accordion from "./components/accordion";
import Modal from "./components/modal";
import { colors } from "../constants/colors";

export const theme = extendTheme({
  colors: {
    blue: {
      400: colors.primary1,
      500: colors.primary,
      900: colors.primary2,
    },
  },
  components: {
    Button,
    Input,
    Textarea,
    Accordion,
    Modal,
  },
  fonts: {
    // body: "Open Sans, sans-serif",
    // heading: "Open Sans, sans-serif",
    // mono: "Menlo, monospace",
    body: "system-ui, sans-serif",
    heading: "Georgia, serif",
    mono: "Menlo, monospace",
  },
});
