import { AxiosRequestConfig } from "axios";
import { env } from "../../environment";

export const chainInterceptor = (config: AxiosRequestConfig) => {
  if (config.headers) {
    config.headers["chain"] = env.config.default_network_name;
  }

  return config;
};
