import axios from "../core/https";

const importBuyers = (body: { buyers: { address: string; creditLimit: number; creditStatus: string }[] }) =>
  axios.post("/accounts/import/buyers", body);

const importSellers = (body: { sellers: { address: string; commissionFee: number; sellerStatus: string }[] }) =>
  axios.post("/accounts/import/sellers", body);

export const accountService = {
  importBuyers,
  importSellers,
};
