interface IEnv {
  config: IEnvConfig;
}

interface IEnvConfig {
  base_url: string;
  default_network_name: string;
  firebase: {
    api_key: string;
    auth_domain: string;
    project_id: string;
    storage_bucket: string;
    messaging_sender_id: string;
    app_id: string;
    measurement_id: string;
  };
}

const getEnv = (): IEnv => {
  const config: IEnvConfig = {
    test1: require("./test1/config.json"),
    dev: require("./dev/config.json"),
    live: require("./live/config.json"),
    default: require("./dev/config.json"),
  }[process.env.REACT_APP_HOST_ENV || "default"];

  return {
    config,
  } as IEnv;
};

export const env = getEnv();
